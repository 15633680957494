import React from "react"
import styled from "styled-components"
import Navigation from "./navigation"

import '../scss/app.scss'
// const Container = styled.div`
//   margin: 3rem auto;
//   max-width: 800px;
//   display: flex;
//   flex-direction: column;
//   align-items: left;
//   justify-content: center;
// `
const Container = styled.div`

`

export default ({ children }) => (
    <Container className="container">
        <header className="header header--scrolled" id="header">
            <div className="container">
                <div className="header__menu">
                    <input type="checkbox" id="menu-burger" className="header__menu-status" hidden />
                    {/* <label for="menu-burger" className="header__burger">
                        <span></span>
                    </label> */}
                    {/* <nav className="header__nav">
                        <div className="header__nav-item">
                            <a href="./works.html" className="header__nav-link text--uppercase">works</a>
                        </div>
                        <div className="header__nav-item">
                            <a href="./index.html#about" className="header__nav-link text--uppercase">about</a>
                        </div>
                        <div className="header__nav-item">
                            <a href="./blog.html" className="header__nav-link text--uppercase">blog</a>
                        </div>
                        <div className="header__nav-item">
                            <a href="./index.html#contacts" className="header__nav-link text--uppercase">contacts</a>
                        </div>
                    </nav> */}
                    {/* <Navigation></Navigation> */}
                    <div className="header__logo">
                        <a href="./index.html" className="logo">
                            Alessandro Vidotto
                            {/* <img src="./img/logo.svg" alt="logo" /> */}
                        </a>
                    </div>
                </div>
            </div>

        </header>
        <main className="home">
            {children}
        </main>
    </Container>
)