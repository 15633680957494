import React from "react"
import { StaticQuery, Link, graphql } from "gatsby"

export default () => (
        <StaticQuery
            query={graphql`
                    query {
                        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
                        totalCount
                        edges {
                            node {
                            id
                            frontmatter {
                                title
                                date(formatString: "DD MMMM, YYYY")
                            }
                            fields {
                                slug
                            }
                            excerpt
                            }
                        }
                        }
                    }
                    `
            }
            render={data => (
                <ul className="nav justify-content-end">{data.allMarkdownRemark.edges.map(({node}) => {
                    return <li key={node.id} className="nav-item"><Link to={node.fields.slug}>{node.frontmatter.title}</Link></li>
                })}</ul>
            )}
        />
)